import React from "react";
import PropTypes from "prop-types";
import "./CommonQs.css";
import { Link } from "react-router-dom";
import whatsApp from "../Images/NavBar/WhatsAppButtonGreenSmall.svg";

const CommonQs = (props) => {
  return (
    <div className="faq-section thq-section-padding">
      <div className="faq-max-width thq-section-max-width">
        <div className="thq-flex-column">
          <h2 className="thq-heading-2">{props.heading1}</h2>
          <p className="faq-text1 thq-body-large">{props.content1}</p>
        </div>
        <div className="thq-flex-column faq-list">
          <div className="faq-list-item">
            <p className="faq-question thq-body-large">{props.faq1Question}</p>
            <span className="faq-answer thq-body-small">
              {props.faq1Answer}
            </span>
          </div>
          <div className="faq-list-item">
            <p className="faq-question thq-body-large">{props.faq2Question}</p>
            <span className="faq-answer thq-body-small">
              {props.faq2Answer}
            </span>
          </div>
          <div className="faq-list-item">
            <p className="faq-question thq-body-large">{props.faq3Question}</p>
            <span className="faq-answer thq-body-small">
              {props.faq3Answer}
            </span>
          </div>
          <div className="faq-list-item">
            <p className="faq-question thq-body-large">{props.faq4Question}</p>
            <span className="faq-answer thq-body-small">
              {props.faq4Answer}
            </span>
          </div>
          <div className="faq-list-item">
            <p className="faq-question thq-body-large">{props.faq5Question}</p>
            <span className="faq-answer thq-body-small">
              {props.faq5Answer}
            </span>
          </div>
        </div>
        <div className="thq-flex-column">
          <div className="faq-content1">
            <h2 className="thq-heading-2">{props.heading2}</h2>
            <p className="faq-text3 thq-body-large">{props.content2}</p>
          </div>
        </div>
        <button className="buttonOrangeFilled" style={{ textAlign: "center" }}>
          <Link to="/Help">Contact Us →</Link>
        </button>
        <a
          aria-label="Chat on WhatsApp"
          href="https://wa.me/353894024355?text=I%27m%20inquiring%20about%20your%20services"
        >
          <img alt="Chat on WhatsApp" src={whatsApp} />
        </a>
      </div>
    </div>
  );
};

CommonQs.defaultProps = {
  heading1: "Common questions",
  content1: "Here are some of the most common questions that we get.",
  faq1Question: "What services does your Company offer?",
  faq1Answer:
    "Our services include a wide range of painting services such as interior painting, exterior painting, and decorative painting in Dublin.",
  faq2Question: "Do you have experienced painters?",
  faq2Answer:
    "Yes, we have skilled and trained painters with years of experience in the industry.",
  faq3Question:
    "Can you customize design solutions according to my preferences?",
  faq3Answer:
    "Yes, we offer tailored painting and design solutions to meet your unique preferences.",
  faq4Question: "Are you insured and licensed?",
  faq4Answer:
    "Yes, our Company is fully insured and licensed. We prioritize the safety of our clients and our team members, and we comply with all necessary regulations and requirements.",
  faq5Question: "What sets your painting services apart?",
  faq5Answer:
    "Our high-quality painting services, experienced painters, and customized design solutions distinguish us in the industry.",
  heading2: "Still have a question?",
  content2: "Feel free to reach out to us. ",
};

CommonQs.propTypes = {
  faq5Question: PropTypes.string,
  content1: PropTypes.string,
  faq1Question: PropTypes.string,
  content2: PropTypes.string,
  faq3Question: PropTypes.string,
  heading1: PropTypes.string,
  faq5Answer: PropTypes.string,
  faq4Answer: PropTypes.string,
  faq2Answer: PropTypes.string,
  faq1Answer: PropTypes.string,
  heading2: PropTypes.string,
  faq2Question: PropTypes.string,
  faq4Question: PropTypes.string,
  faq3Answer: PropTypes.string,
};

export default CommonQs;
